import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {AuthentificationService} from "../../../services/authentification.service";
import {BehaviorSubject, Subject, takeUntil} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {UploadFileFormComponent} from "../../result/upload-file-form/upload-file-form.component";
import {ICamp} from "../../../models/camp";
import {DatabaseService} from "../../../services/db/database.service";
import {CampResultFormComponent} from "../results/camp-result-form/camp-result-form.component";

@Component({
  selector: 'app-camp-result-html',
  templateUrl: './camp-result-html.component.html',
  styleUrls: ['./camp-result-html.component.scss'],
  host: {'class': 'base-host-center styled-background'},
})
export class CampResultHtmlComponent implements OnInit, OnDestroy {
  @Input() campId!: string;
  isLoggedIn$: BehaviorSubject<boolean>;
  destroy$ = new Subject<boolean>();

  constructor(
    private authService: AuthentificationService,
    private matDialog: MatDialog,
    private databaseService: DatabaseService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isAuthenticated$;


  }

  protected readonly faPlus = faPlus;
  @Input() camp: ICamp;

  openAdd() {
    let addDialog = this.matDialog.open(UploadFileFormComponent, {
      data: {camp: this.camp}
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
