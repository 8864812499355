import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-coupe-title',
  templateUrl: './set-coupe-title.component.html',
  styleUrls: ['./set-coupe-title.component.scss'],
  host: {'class': 'base-host-start'}
})
export class SetCoupeTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
