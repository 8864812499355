import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from 'rxjs';
import {AuthentificationService} from "./services/authentification.service";
import { ScreenService } from './services/screen/screen.service';
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './css/mat-tab-custom.scss'],
  providers: [ScreenService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'BridgePro';
  isMobileSubject: Observable<boolean>;

  constructor(private authService: AuthentificationService, private screenService: ScreenService) {
    this.authService.initAuthListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnInit(): void {
    this.isMobileSubject = this.screenService.isMobileSubject;
  }

  destroy$ = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
