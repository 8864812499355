export class TournamentViewModel2 {
  playerId: string;
  playerName: string;
  averageScore: number;
  averageScoreString: string;
  totalScore: number;
  totalScoresString: string;
  scoresByDate: { [date: number]: number };

  constructor(playerName: string, scoresByDate: { [date: number]: number }) {
    this.playerName = playerName;
    this.scoresByDate = scoresByDate;
    this.calculateAverageScore();
  }

  calculateAverageScore() {
    const scoreValues = Object.values(this.scoresByDate);
    const totalScore = scoreValues.reduce((acc, score) => acc + score, 0);
    this.averageScore = scoreValues.length > 0 ? totalScore / scoreValues.length : 0;
    this.averageScoreString = this.getAverageString();
  }

  //Overcharge calculate average score with a parameter of type number expressing the number of game to account in the average. 15 best

  getAverageString() {
    return this.averageScore.toFixed(2);
  }

  calculateTotalScores() {
  this.totalScore = Object.values(this.scoresByDate).reduce((acc, score) => acc + score, 0);
  this.totalScoresString = this.totalScore.toFixed(2);
}

  getTotalScoresString() {
    return this.totalScoresString;
  }

  addScore(date: number, score: number) {
    this.scoresByDate[date] = Number(score);
    this.calculateAverageScore();
    this.calculateTotalScores();
  }

  removeScore(date: number) {
    delete this.scoresByDate[date];
    this.calculateAverageScore();
    this.calculateTotalScores();
  }

  getScoresByDate() {
    return this.scoresByDate;
  }

  getScoreForDate(date: string) {
    const timestamp = new Date(date).getTime();
    return this.scoresByDate[timestamp];
  }

}
