import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {InfosComponent} from "./components/infos/infos.component";
import {DiaryComponent} from "./components/diary/diary.component";
import {Handicap22Component} from "./components/handicap22/handicap22.component";
import {CoupeClpComponent} from "./components/coupe-clp/coupe-clp.component";
import {LoginFormComponent} from "./components/forms/login-form/login-form.component";
import {NewTournamentComponent} from "./components/tournament/new-tournament/new-tournament.component";
import {canActivate, redirectLoggedInTo} from "@angular/fire/compat/auth-guard";
import {AuthGuard} from "@angular/fire/auth-guard";
import {TournamentListComponent} from "./components/tournament/tournament-list/tournament-list.component";
import {RegistrationListComponent} from "./components/registrations/registration-list/registration-list.component";
import {UploadHtmlComponent} from "./components/result/upload-html/upload-html.component";
import {ResultListComponent} from "./components/result/result-list/result-list.component";
import {ClPerronTeamsComponent} from "./components/cl-perron/cl-perron-teams/cl-perron-teams.component";
import {EditTournamentComponent} from "./components/tournament/edit-tournament/edit-tournament.component";
import {RegistrationFormComponent} from "./components/registrations/registration-form/registration-form.component";
import {
  UpdateRegistrationComponent
} from "./components/registrations/update-registration/update-registration.component";
import {MondayChallengesComponent} from "./components/general-rankings/monday-challenges/monday-challenges.component";
import {CLPerronRankingComponent} from "./components/cl-perron/clperron-ranking/clperron-ranking.component";
import {MondayChampagneComponent} from "./components/general-rankings/monday-champane/monday-champagne.component";
import {LinksComponent} from "./components/liens/links.component";
import {CommentaryListComponent} from "./components/result/commentary-list/commentary-list.component";
import {ClPerronRulesComponent} from "./components/cl-perron/cl-perron-rules/cl-perron-rules.component";
import {ClPerronCalendarComponent} from "./components/cl-perron/calendar/cl-perron-calendar.component";
import {
  CompetitionClPerronTeamsComponent
} from "./components/competitions/competition-cl-perron-teams/competition-cl-perron-teams.component";
import {
  CompetitionCaptainsComponent
} from "./components/competitions/competition-captains/competition-captains.component";
import {
  MyCompetitionSystemComponent
} from "./components/competitions/my-competition-system/my-competition-system.component";

import {RTEComponent} from "./components/rte/rte.component";
import {HomeComponent} from "./components/home/home.component";
import {CompetitionsComponent} from "./components/competitions/competitions/competitions.component";
import {EditNewsComponent} from "./components/infos/edit-news/edit-news.component";
import {ContactComponent} from "./components/contact/contact.component";
import {CreateTeamComponent} from "./components/clp-manage-league/create-team/create-team.component";
import {ShowTeamsComponent} from "./components/clp-manage-league/show-teams/show-teams.component";
import {DeleteTeamComponent} from "./components/clp-manage-league/delete-team/delete-team.component";
import {UpdateTeamComponent} from "./components/clp-manage-league/update-team/update-team.component";
import {CreateTeamResultComponent} from "./components/teamResult/create-team-result/create-team-result.component";
import {UpdateTeamResultComponent} from "./components/teamResult/update-team-result/update-team-result.component";
import {DeleteTeamResultComponent} from "./components/teamResult/delete-team-result/delete-team-result.component";
import {ViewTeamResultsComponent} from "./components/teamResult/view-team-results/view-team-results.component";
import {CampForm} from "./components/camps/create-camp/camp-form.component";
import {ViewCampComponent} from "./components/camps/view-camp/view-camp.component";
import {ViewPlayersComponent} from "./components/competition/folder/view-players/view-players.component";
import {ResultArrayComponent} from "./components/competition/folder/view-results/result-array.component";
import {MondayResultsComponent} from "./components/competition/folder/monday-results/monday-results.component";
import {ClpResultsComponent} from "./components/competition/folder/clp-results/clp-results.component";
import {SetCoupeTitleComponent} from "./components/titles/set-coupe-title/set-coupe-title.component";

const redirectLoggedInToHome = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {path: '', component: InfosComponent},
  {path: 'infos', component: InfosComponent},
  {path: 'diary', component: DiaryComponent},


  {path: 'handicap', component: Handicap22Component},
  {path: 'competitions', component: CompetitionsComponent},
  {path: 'register-tournament', component: RegistrationFormComponent},
  {path: 'registeration-list', component: RegistrationListComponent},
  {path: 'new-tournaments', component: NewTournamentComponent, canActivate: [AuthGuard]},
  {path: 'update-tournament', component: EditTournamentComponent, canActivate: [AuthGuard]},
  {path: 'update-registration', component: UpdateRegistrationComponent, canActivate: [AuthGuard]},
  {path: 'tournament-list', component: TournamentListComponent, canActivate: [AuthGuard]},
  {path: 'coupeclp', component: CoupeClpComponent},
  {path: 'monday-ranking', component: MondayChallengesComponent},
  {path: 'monday-champagne', component: MondayChampagneComponent},
  {path: 'links', component: LinksComponent},
  {path: 'clp-ranking', component: CLPerronRankingComponent},
  {path: 'clp-rules', component: ClPerronRulesComponent},
  {path: 'results', component: ResultListComponent},
  {path: 'commentary-list', component: CommentaryListComponent},
  {path: 'cl-perron-teams', component: ClPerronTeamsComponent},
  {path: 'cl-perron-calendar', component: ClPerronCalendarComponent},
  {path: 'competition-cl-perron-teams', component: CompetitionClPerronTeamsComponent},
  {path: 'competition-captains', component: CompetitionCaptainsComponent},
  {path: 'my-competition-system', component: MyCompetitionSystemComponent},
  {path: 'upload-resultats', component: UploadHtmlComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginFormComponent, ...canActivate(redirectLoggedInToHome) },
  {path: 'rte', component: RTEComponent, canActivate: [AuthGuard]},
  {path: 'edit-news/:id', component: EditNewsComponent, canActivate: [AuthGuard]},
  {path: 'contact', component: ContactComponent},


  {path: 'create-team', component: CreateTeamComponent, canActivate: [AuthGuard]},
  {path: 'update-team/:id', component: UpdateTeamComponent, canActivate: [AuthGuard]},
  {path: 'delete-team/:id', component: DeleteTeamComponent, canActivate: [AuthGuard]},
  {path: 'show-teams', component: ShowTeamsComponent},

  {path: 'create-result', component: CreateTeamResultComponent},
  {path: 'update-result', component: UpdateTeamResultComponent},
  {path: 'delete-result', component: DeleteTeamResultComponent},
  {path: 'coupe-params', component: SetCoupeTitleComponent, canActivate: [AuthGuard]},

  {path: 'show-results', component: ViewTeamResultsComponent},
  {path: 'show-clp-results', component: ClpResultsComponent},



  {path: 'manage-camp', component: CampForm, canActivate: [AuthGuard]},
  {path: 'manage-camp/:id', component: CampForm},
  {path: 'view-camp/:id', component: ViewCampComponent},


  {path: 'view-players', component: ViewPlayersComponent},
  {path: 'view-results', component: MondayResultsComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
