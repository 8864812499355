import {Component, OnDestroy, OnInit} from '@angular/core';
import {TournamentService} from "../../../services/db/tournament.service";
import {Tournament, TournamentFactory} from "../../../models/tournament";
import {StringUtils} from "../../../models/utils/string-utils";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RegistrationService} from "../../../services/db/registration.service";
import {Router} from "@angular/router";
import {map, Subject, takeUntil} from "rxjs";
import {AuthentificationService} from "../../../services/authentification.service";
import {ScreenService} from "../../../services/screen/screen.service";

@Component({
  selector: 'app-tournament-list',
  templateUrl: './tournament-list.component.html',
  styleUrls: ['./tournament-list.component.scss'],
  host: {'class': 'base-host-center styled-background'}
})
export class TournamentListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  displayedTournaments: Tournament[];
  tournaments: Tournament[];
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;

  getDateString = StringUtils.getDateString;
  filterText: string;
  message: string;
  messageColor: string;
  columnMode = 'flex';

  constructor(private db: TournamentService,
              private tournamentService: TournamentService,
              private registrationService: RegistrationService,
              private router: Router,
              private auth: AuthentificationService,
              private screenService: ScreenService,
              private snackBar: MatSnackBar
  ) {
    this.message = "";
    this.messageColor = "";
    this.displayedTournaments = []
  }

  ngOnInit(): void {
    this.tournamentService.getTournaments()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      res = res.sort((a, b) => {  // Sort by date
        return a.date.getTime() - b.date.getTime();
      });
      this.displayedTournaments = res;
      this.tournaments = res;

        console.log(this.tournaments)
    });

    this.columnMode = this.screenService.isMobile ? 'standard' : 'flex';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onChange(value: any) {
    console.log(value)
    this.displayedTournaments = this.tournaments.filter((t) => {
      return StringUtils.getDateAndLocationString(t)
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    });
  }

  delete(id: string) {
    this.displayedTournaments = this.displayedTournaments.filter(t => t.id !== id);
    this.db.deleteData(id).then(() => {
      this.snackBar.open("Tournoi supprimé.", "OK", {duration: 2000});
    }, () => {
      this.snackBar.open("Erreur lors de la suppression du tournoi.", "OK", {duration: 2000});
    });
  }

  goToEdit(id: string) {

    this.router.navigate(['/update-tournament', {id: id}]);
  }

  get isLogged(): boolean {
    return this.auth.isLoggedIn();
  }
}
