import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthentificationService } from '../../services/authentification.service';
import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { DatabaseService } from '../../services/db/database.service';
import { DbKeys } from '../../services/db/config/db-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { QuerySnapshot } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-liens',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  host: { class: 'base-host-center styled-background' },
})
export class LinksComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  @Input() saveLocationKey: string;
  links = [
    /* {
       url: 'http://www.worldbridge.org/',
       title: 'Fédération Mondiale de Bridge',
     },
     {
       url: 'http://www.eurobridge.org/',
       title: 'Féderation Européenne de Bridge',
     },
     { url: 'https://www.rbbf.be/', title: 'Fédération Belge de Bridge' },
     { url: 'http://www.lbf.be/', title: 'Ligue Francophone de Bridge' },

     //{url: "http://www.bridge-chailley.fr/", title: "Comprendre le Bridge (Challey)"},
   */
  ] as Link[];
  editing: string[] = [];
  isEditing = true;
  isAuthenticated$: BehaviorSubject<boolean>;
  canSave = true;
  private updateId: any;

  constructor(
    private authService: AuthentificationService,
    private db: DatabaseService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    if(!this.linksTitle) {
      this.linksTitle = "Liens utiles";
    }
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    //if saveLocationKey is not provided then use the route name
    if (!this.saveLocationKey) {
      this.saveLocationKey = this.router.url;
    }

    //get links from db
    try {
      this.db
        .getWhereChained(DbKeys.links, [
          {
            field: 'saveLocationKey',
            operator: '==',
            value: this.saveLocationKey,
          },
        ])
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {


          if (data.length > 0) {
            this.links = data[0].data;
            this.updateId = data[0].id;
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addLink() {
    this.links.push({ url: '', title: '' });
  }

  removeLink() {}

  delete() {
    //sort the array to prevent index shifting
    this.editing.forEach((val) => {
      this.links.splice(Number(val), 1);
    });
    this.editing = []; // clear the editing array
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    this.editing = [];
  }

  swap() {
    if (this.editing.length === 2) {
      let aIndex = Number(this.editing[0]);
      let bIndex = Number(this.editing[1]);

      let temp = this.links[aIndex];
      this.links[aIndex] = this.links[bIndex];
      this.links[bIndex] = temp;

      this.editing = [];
    } else {
      alert(
        "L'inversion nécessite de sélectionner deux liens. (ni plus ni moins)"
      );
    }
  }

  saveLinks() {
    this.canSave = false;
    if (this.updateId) {
      this.updateLinks();
    } else {
      this.addLinks();
    }
  }

  //saveLinks2 remove empty links
  @Input() linksTitle: string;


  private addLinks() {
    this.db
      .addData(
        {
          saveLocationKey: this.saveLocationKey,
          data: this.links,
        },
        DbKeys.links
      )
      .then(() => {
        this.snackbar.open('Liens sauvegardés', 'Fermer', { duration: 2000 });
      })
      .catch((err) => {
        this.snackbar.open('Erreur lors de la sauvegarde', 'Fermer', {
          duration: 2000,
        });
      })
      .finally(() => {
        this.canSave = true;
      });
  }

  private updateLinks() {
    this.canSave = false;
    this.db
      .updateData(this.updateId, { data: this.links }, DbKeys.links)
      .then(() => {
        this.snackbar.open('Liens sauvegardés', 'Fermer', { duration: 2000 });
      })
      .catch(() => {
        this.snackbar.open('Erreur lors de la sauvegarde', 'Fermer', {
          duration: 2000,
        });
      })
      .finally(() => {
        this.canSave = true;
      });
  }
}

interface Link {
  url: string;
  title: string;
}
