import {Component, OnDestroy, OnInit} from '@angular/core';
import {TournamentService} from "../../../services/db/tournament.service";
import {Tournament, TournamentImpl} from "../../../models/tournament";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RegistrationService} from "../../../services/db/registration.service";
import {Registration} from "../../../models/registration";
import {StringUtils} from "../../../models/utils/string-utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatabaseService} from "../../../services/db/database.service";
import {DbKeys} from "../../../services/db/config/db-config";
import {AuthentificationService} from "../../../services/authentification.service";
import {Subject, takeUntil} from "rxjs";



@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
  host: { class: 'styled-background' },
})
export class RegistrationFormComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();

  data: TournamentImpl[];
  registrationForm = new FormGroup({
    tournamentId: new FormControl(''),
    player1: new FormControl(''),
    player2: new FormControl(''),
  });
  messageColor: any;
  message: string;
  submitted: boolean;
  canSubmit: boolean;
  stringUtils: StringUtils;
  getDateAndLocationString = StringUtils.getDateAndLocationString;
  disableForm: boolean;
  showReserveMessage: boolean;

  constructor(
    private registrationService: RegistrationService,
    private tournamentService: TournamentService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private db: DatabaseService,
    private auth: AuthentificationService
  ) {
    this.message = '';
    this.messageColor = 'text-primary';
    this.stringUtils = new StringUtils();
    this.submitted = false;
    this.canSubmit = true;
    this.data = [];
    this.disableForm = false;
  }

  ngOnInit(): void {
    this.tournamentService
      .getTournaments()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.data = (res).sort((a, b) => a.date.getTime() - b.date.getTime());
      });
    this.registrationForm = this.formBuilder.group({
      tournamentId: ['', Validators.required],
      player1: ['', [Validators.required, Validators.minLength(3)]],
      player2: ['', [Validators.required, Validators.minLength(3)]],
    });

    //get stored registration array from local storage
    let storedRegistration = JSON.parse(
      localStorage.getItem('registrations') || '[]'
    ) as Registration[];

    this.registrationForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        let value = this.registrationForm.value;
        let registration = {
          player1: value.player1,
          player2: value.player2,
          tournamentId: value.tournamentId,
        } as Registration;
        if (
          storedRegistration.some(
            (r) =>
              r.player1 === registration.player1 &&
              r.player2 === registration.player2 &&
              r.tournamentId === registration.tournamentId &&
              !this.auth.isLoggedIn()
          )
        ) {
          this.disableForm = true;
          this.snackbar.open('Vous êtes déjà inscrit à ce tournoi', 'Fermer', {
            duration: 5000,
          });
        } else {
          this.disableForm = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get player1() {
    return this.registrationForm.get('player1') as FormControl;
  }
  get player2() {
    return this.registrationForm.get('player2') as FormControl;
  }
  get tournamentId() {
    return this.registrationForm.get('tournamentId');
  }

  async onSubmit(value: Registration): Promise<void> {
    this.submitted = true;

    if (this.registrationForm.invalid || !this.canSubmit) {
      return;
    }

    this.canSubmit = false;

    try {
      let registration = {
        ...value,
        dateInscription: new Date(),
        isReserve: this.isReserve(this.findTournamentById(value.tournamentId)),
      };
      await this.registrationService.addRegistration(registration);
      const tournament = this.findTournamentById(value.tournamentId);

      if (tournament) {
        let tournamentName =
          RegistrationFormComponent.getTournamentName(tournament);
        await this.updateTournamentAndNotify(tournament, tournamentName, registration);
      }
    } catch (error) {
      this.snackbar.open('Erreur lors de la réservation');
    } finally {
      this.canSubmit = true;
    }
  }

  private findTournamentById(tournamentId: string): Tournament | undefined {
    return this.data.find((t) => t.id === tournamentId);
  }

  private async updateTournamentAndNotify(
    tournament: Tournament,
    tournamentName: string,
    registration: Registration
  ): Promise<void> {
    if (tournament.id != null) {
      await this.db.updateData(tournament.id, tournament, DbKeys.tournaments);
    }
    this.snackbar.open(
      `Inscription effective pour le tournoi ${tournamentName}.`,
      'Fermer'
    );
    if(!registration.isReserve) {
      tournament.registrationCount++;
    } else {
      tournament.reserveRegistrationCount++;
    }
    RegistrationFormComponent.updateLocalRegistrations(registration);
    this.onReset();
  }

  isComplete(tournament?: Tournament): boolean {
    tournament = tournament ?? this.findTournamentById(this.tournamentId?.value ?? "");

    if(!tournament) {
      return false;
    }

    return (
      tournament.registrationCount >= tournament.maxRegistrations &&
      (tournament.reserveRegistrationCount ?? 0) >= (tournament.maxReserveRegistrations ?? 0)
    );
  }

  isReserve(tournament?: Tournament): boolean {
    tournament = tournament ?? this.findTournamentById(this.tournamentId?.value ?? "")

    if(!tournament) {
      return false;
    }

    return (
      tournament.registrationCount >= tournament.maxRegistrations &&
      tournament.reserveRegistrationCount < tournament.maxReserveRegistrations
    );
  }

  registrationSelectString(tournament: Tournament): string {
    const reserveString = ` (Réserve : ${tournament.reserveRegistrationCount}/${tournament.maxReserveRegistrations ?? "?"})`;
    return `${this.getDateAndLocationString(tournament, true)} (${tournament.registrationCount}/${tournament.maxRegistrations})${reserveString}`;
  }

  private static getTournamentName(tournament?: Tournament): string {
    return (
      StringUtils.getDateAndLocationString(tournament) || 'Erreur'
    );
  }

  private static updateLocalRegistrations(registration: Registration): void {
    const storedRegistrations = JSON.parse(
      localStorage.getItem('registrations') || '[]'
    ) as Registration[];
    storedRegistrations.push(registration);
    localStorage.setItem('registrations', JSON.stringify(storedRegistrations));
  }

  getTextColor(tournament: Tournament): string {
    if (this.isComplete(tournament)) {
      return 'text-danger';
    }

    if (this.isReserve(tournament)) {
      return 'text-warning';
    }

    return '';
  }

  onReset(): void {
    this.submitted = false;
    this.registrationForm.markAsUntouched();
    this.registrationForm.markAsPristine();
    this.registrationForm.reset();
  }

  onTournamentChange($event: TournamentImpl) {
    if(this.isReserve($event)) {
      this.snackbar.open('Votre inscription sera enregistrée en tant qu\'inscription de réserve. Vous serez contacté en cas de désistement, sinon, vous ne participerez pas au tournoi.', 'Fermer', {
      });
      } else {
      this.showReserveMessage = false;
    }


  }

  getSelectedTextColor(): string {
    const selectedTournament = this.data.find(tournament => tournament.id === this.tournamentId?.value);
    if (selectedTournament) {
      return this.getTextColor(selectedTournament);
    }
    return ''; // Return a default class or an empty string if no tournament is selected
  }

  getSelectedTournamentName(): string {
    const selectedTournament = this.data.find(tournament => tournament.id === this.tournamentId?.value);
    if (selectedTournament) {
      return this.registrationSelectString(selectedTournament);
    }
    return ''; // Return an empty string if no tournament is selected
  }
}
