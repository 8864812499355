import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  first,
  from,
  map,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import {
  Camp,
  CampProgram,
  ICamp,
  ICampResult,
  ICampSequency,
  IScore,
} from '../../models/camp';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { CampPlayer, Player } from '../../models/camp/player';
import { DbKeys } from './config/db-config';
import { IResult } from '../../models/IResult';
import { arrayRemove, arrayUnion } from '@angular/fire/firestore';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root',
})
export class CampService implements OnDestroy {
  private campCollection;
  private campPlayersCollection;
  private campSequenciesCollection;
  private campInformationsCollection;
  private campBidCollection;
  private campResultCollection;

  private campProgramCollection;

  private _camps = new BehaviorSubject<ICamp[]>([]);
  readonly camps$ = this._camps.asObservable();

  destroy$ = new Subject<boolean>();

  constructor(private afs: AngularFirestore, private fileService: FileService) {
    this.campCollection = this.afs.collection<ICamp>(DbKeys.camps);
    this.campPlayersCollection = this.afs.collection<Player>(
      DbKeys.campPlayers
    );
    this.campSequenciesCollection = this.afs.collection(DbKeys.campSequencies);
    this.campInformationsCollection = this.afs.collection<CampInformation>(
      DbKeys.campInformations
    );
    this.campBidCollection = this.afs.collection<CampBid>(DbKeys.campBids);
    this.campResultCollection = this.afs.collection<CampHtmlResult>(
      DbKeys.campResults
    );
    this.campProgramCollection = this.afs.collection(DbKeys.campProgram);

    this.fetchCamps();
  }

  private fetchCamps(): void {
    this.campCollection
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as ICamp;
            const id = a.payload.doc.id;
            return new Camp(
              data.name,
              data.password,
              (data.results as IResult[]) || [],
              id
            );
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((camps) => this._camps.next(camps));
  }

  getCampProgram(campId: string) {
    return this.campProgramCollection.ref
      .where('campId', '==', campId)
      .get()
      .then((querySnapshot) => {
        let programs: { id: string; campId: string; url: string }[] = [];
        querySnapshot.forEach((doc) => {
          const item = doc.data() as CampProgram;
          programs.push({ id: doc.id, campId: item.campId, url: item.url });
        });
        return programs;
      });
  }

  addCamp(camp: ICamp): Promise<DocumentReference<unknown>> {
    return this.campCollection.add(camp);
  }

  getCamps(): Observable<ICamp[]> {
    return this.camps$;
  }

  /*getCamps(): Observable<ICamp[]> {
    return this.campCollection.get().pipe(
      map((actions) =>
        actions.docs.map((a) => {
          return new Camp(
            a.data().name,
            a.data().password,
            (a.data().results as IResult[]) || [],
            a.id
          );
        })
      )
    );
  }*/

  getCampById(id: string): Observable<ICamp | undefined> {
    return this.camps$.pipe(
      map((camps) => {
        return camps.find((camp) => camp.id === id);
      })
    );
  }

  /*getCampById(id: string): Observable<ICamp> {
    return this.campCollection
      .doc(id)
      .get()
      .pipe(
        map((action) => {
          const data = action.data() as ICamp;
          data.results = data.results?.map((result) => {
            // @ts-ignore
            result.date = result.date.toDate();
            return result;
          });
          const id = action.id;
          const camp = new Camp(
            data.name,
            data.password,
            (data.results as IResult[]) || [],
            id
          );
          return camp;
        })
      );
  }*/

  //update a camp by id using same process as precedent function
  updateCamp(id: string, data: any): Promise<void> {
    return this.campCollection.doc(id).update(data);
  }

  deleteCamp(id: string): Promise<void> {
    return this.campCollection.doc(id).delete();
  }

  addCampPlayer(campPlayer: CampPlayer): Promise<DocumentReference<unknown>> {
    return this.campPlayersCollection.add(campPlayer);
  }

  /*getCampPlayers(campId: string): Observable<Player[]> {
    return this.campPlayersCollection.get().pipe(
      map((actions) => {
        return actions.docs.map((a) => {
          const data = a.data() as Player;
          const id = a.id;
          const player = { id, ...data } as Player;
          return player;
        });
      }),
      map((players) => players.filter((player) => player.campId === campId))
    );
  }*/

  getCampPlayers(campId: string): Observable<Player[]> {
    return this.campPlayersCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as Player;
          const id = a.payload.doc.id;
          const player = { id, ...data } as Player;
          return player;
        });
      }),
      map((players) => players.filter((player) => player.campId === campId))
    );
  }

  getCampPlayerById(id: string): Observable<Player> {
    return this.campPlayersCollection
      .doc(id)
      .get()
      .pipe(
        map((action) => {
          const data = action.data() as Player;
          const id = action.id;
          const player = new CampPlayer(
            data.campId,
            data.firstName,
            data.lastName,
            id
          );
          return player;
        })
      );
  }

  updateCampPlayer(id: string, data: any): Promise<void> {
    return this.campPlayersCollection.doc(id).update(data);
  }

  //ALso needs to delete the results associated to the player
  deleteCampPlayer(id: string): any {
    //Gets the player to get the camp id
    this.getCampPlayerById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((player) => {
        let id = player.id;
        let campId = player.campId;
      });

    return this.campPlayersCollection
      .doc(id)
      .delete()
      .then(() => {});
  }

  deletePlayerScores(playerId: string, campId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.camps$
        .pipe(
          map((camps) => camps.find((camp) => camp.id === campId)),
          first()
        )
        .subscribe((camp) => {
          if (!camp) {
            resolve();
            return;
          }

          camp.results = camp.results?.map((result) => {
            result.scores = result.scores?.filter(
              (score) => score.playerId !== playerId
            );

            return result;
          });

          //Remove empty scores
          camp.results = camp.results?.filter((result) => {
            if (result.scores) {
              return result.scores.length > 0;
            }
            return false;
          });

          // Update the camp document in the campCollection with the new camp results
          this.campCollection
            .doc(campId)
            .update({ results: camp?.results })
            .then(() => {
              resolve();
            });
        });
    });
  }

  addCampResult(campId: string, result: ICampResult): Promise<void> {
    return this.campCollection.doc(campId).update({
      results: arrayUnion(result) as unknown as ICampResult[],
    });
  }

  removeCampResult(campId: string, result: IResult): Promise<void> {
    return this.campCollection.doc(campId).update({
      results: arrayRemove(result) as unknown as ICampResult[],
    });
  }

  // Update an existing result in a camp
  updateCampResult(
    campId: string,
    oldResult: ICampResult,
    newResult: ICampResult
  ): Promise<void> {
    return this.campCollection
      .doc(campId)
      .update({
        results: arrayRemove(oldResult) as unknown as ICampResult[],
      })
      .then(() => {
        return this.campCollection.doc(campId).update({
          results: arrayUnion(newResult) as unknown as ICampResult[],
        });
      });
  }

  updateCampResults(campId: string, results: ICampResult[]): Promise<void> {
    return this.campCollection.doc(campId).update({
      results: results,
    });
  }

  deleteCampResult(campId: string, result: ICampResult): Promise<void> {
    return this.campCollection.doc(campId).update({
      results: arrayRemove(result) as unknown as ICampResult[],
    });
  }

  addSequencyToCamp(
    campId: string,
    url: string,
    title: string,
    value: any
  ): Promise<DocumentReference<unknown>> {
    return this.campSequenciesCollection.add({
      campId: campId,
      url: url,
      title: title,
      fileDate: value,
    });
  }

  getCampSequencies(campId: string): Observable<ICampSequency[]> {
    return this.afs
      .collection<ICampSequency>(DbKeys.campSequencies, (ref) =>
        ref.where('campId', '==', campId)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as ICampSequency;
            const id = a.payload.doc.id;
            return { ...data, id };
          })
        ),
      );
  }

  getCampResults(campId: string) {
    return this.campResultCollection.ref
      .where('campId', '==', campId)
      .get()
      .then((querySnapshot) => {
        let results: CampHtmlResult[] = [];
        querySnapshot.forEach((doc) => {
          results.push({
            campId: doc.get('campId'),
            id: doc.id,
            name: doc.get('name'),
            url: doc.get('url'),
            date: doc.get('date').toDate(),
          });
        });
        return results.sort((a, b) => {
          return a.date.getTime() - b.date.getTime();
        });
      });
  }

  deleteCampResultHTML(id: string): Promise<void> {
    return this.campResultCollection.doc(id).delete();
  }

  getCampSequencyBiId(seqId: string): Observable<ICampSequency> {
    return this.campSequenciesCollection
      .doc(seqId)
      .get()
      .pipe(
        map((action) => {
          const data = action.data() as ICampSequency;
          const id = action.id;
          const sequency = { ...data, id } as ICampSequency;
          return sequency;
        })
      );
  }

  setCampNameAndPassword(
    campId: string,
    name: string,
    password: string
  ): Promise<void> {
    return this.campCollection.doc(campId).update({
      name: name,
      password: password,
    });
  }

  //Delete camp, it's players and it's sequencies
  //Collection camp-players and camp-sequencies contains documents that have a campId. Camp have an id
  //So we can delete all the documents that have the campId of the camp we want to delete
  // => where campId == campIdToDelete
  //Also, campSequencies have an attribute url. it's a file url. Use the fileService to delete the file associated to
  // the camp sequency like this : this.fileService.deleteFile()
  deleteCampAndRelatedData(campId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.campCollection
        .doc(campId)
        .delete()
        .then(() => {
          this.campPlayersCollection.ref
            .where('campId', '==', campId)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.delete();
              });
            })
            .then(() => {
              this.campSequenciesCollection.ref
                .where('campId', '==', campId)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const items = doc.data() as ICampSequency;
                    this.fileService.deleteFile(items.url);
                    doc.ref.delete();
                  });
                })
                .then(() => {
                  resolve();
                });
            });
        });
    });
  }

  getCampinformations(campId: string): Observable<CampInformation> {
    return from(
      this.campInformationsCollection.ref.where('campId', '==', campId).get()
    ).pipe(
      map((querySnapshot) => {
        let campInformation: CampInformation | null = null;

        querySnapshot.forEach((doc) => {
          // Assuming the document data is of the type CampInformation
          campInformation = { id: doc.id, ...doc.data() } as CampInformation;
        });

        if (campInformation) {
          return campInformation;
        } else {
          throw new Error(`CampInformation not found for campId: ${campId}`);
        }
      })
    );
  }

  updateCampInformation(campInformation: CampInformation): Promise<void> {
    return this.campInformationsCollection
      .doc(campInformation.id)
      .update(campInformation);
  }

  addCampInformation(
    campInformation: CampInformation
  ): Promise<DocumentReference<unknown>> {
    return this.campInformationsCollection.add(campInformation);
  }

  addCampBid(CampBid: CampBid): Promise<DocumentReference<unknown>> {
    return this.campBidCollection.add(CampBid);
  }

  deleteCampBid(campBidId: string): Promise<void> {
    return this.campBidCollection.doc(campBidId).delete();
  }

  getCampBids(campId: string): Observable<CampBid[]> {
    return from(
      this.campBidCollection.ref.where('campId', '==', campId).get()
    ).pipe(
      map((querySnapshot) => {
        let campBids: CampBid[] = [];

        querySnapshot.forEach((doc) => {
          // Assuming the document data is of the type CampBid
          campBids.push({ id: doc.id, ...doc.data() } as CampBid);
        });

        return campBids;
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

export interface CampInformation {
  id?: string;
  campId: string;
  html: string;
}

export interface CampBid {
  id?: string;
  campId: string;
  name: string;
  url: string;
}

export interface CampHtmlResult {
  id?: string;
  campId: string;
  name: string;
  url: string;
  date: Date;
}
