export enum DbKeys {
  tournaments = 'tournaments',
  registrations = 'registrations',
  results = 'results',
  commentary = 'commentary',
  news = 'news',
  teams = 'teams',
  clpSequences = 'clp-sequences',
  camps = 'camps',
  campSequencies = 'camp-sequencies',
  campPlayers = 'camp-players',
  pictures = 'pictures',
  campBids = 'camp-bids',
  campPlans = 'camp-plans',
  campInformations = 'camp-informations',
  campResults = 'camp-results',
  campProgram = 'camp-program',
  routeTitles = 'route-titles',
  competitionPlayers = 'competition-players',
  links = 'links',
  playerNames = 'player-names',
}


/*export enum DbKeys {
  tournaments = 'tournamentsDev',
  registrations = 'registrationsDev',
  results = 'resultsDev',
  commentary = 'commentaryDev',
  news = 'newsDev',
  teams = 'teamsDev',
  clpSequences = 'clp-sequencesDev',
  camps = 'campsDev',
  campSequencies = 'camp-sequenciesDev',
  campPlayers = 'camp-playersDev',
  pictures = 'picturesDev',
  campBids = 'camp-bidsDev',
  campPlans = 'camp-plansDev',
  campInformations = 'camp-informationsDev',
  campResults = 'camp-resultsDev',
  campProgram = 'camp-programDev',
  routeTitles = 'route-titlesDev',
  competitionPlayers = 'competition-playersDev',
  links = 'linksDev',
  playerNames = 'player-namesDev',
}*/


