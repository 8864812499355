import {Component, OnDestroy, OnInit, LOCALE_ID} from '@angular/core';
import {TournamentService} from "../../../services/db/tournament.service";
import {Tournament} from "../../../models/tournament";
import {RegistrationService} from "../../../services/db/registration.service";
import {MatTableDataSource} from "@angular/material/table";
import {Registration, RegistrationFactory} from "../../../models/registration";
import {StringUtils} from "../../../models/utils/string-utils";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {DatabaseService} from "../../../services/db/database.service";
                import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthentificationService} from "../../../services/authentification.service";
import {Subject, takeUntil} from "rxjs";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ScreenService} from "../../../services/screen/screen.service";

registerLocaleData(localeFr, 'fr-FR')

@Component({
  selector: 'app-registration-list',
  templateUrl: './registration-list.component.html',
  styleUrls: ['./registration-list.component.scss'],
  host: {'class': 'base-host-center styled-background'},
  providers: [
    { provide: LOCALE_ID, useValue: "fr-FR" }
  ]
})
export class RegistrationListComponent implements OnInit, OnDestroy {


  destroy$ = new Subject<boolean>();

  tournaments: Tournament[];
  tournamentsMap: Map<string, Tournament>;
  displayedRows: RowInterface[];
  dataSource: MatTableDataSource<Tournament>;
  private registrations: Row[];
  stringUtils: StringUtils;
  getDateAndLocationString = StringUtils.getDateAndLocationString;
  getDateStringLong = StringUtils.getDateStringLong;
  faPenToSquare = faPenToSquare;
  faTrash = faTrash;
  private message: string;
  private messageColor: string;
  columndefs = ["tournamentName", "player1", "player2", "location", "actions"];
  dropdownOptions: { label: string; value: string | undefined }[];

  constructor(
    private snackbar: MatSnackBar,
    private tournamentService: TournamentService,
    private registrationsService: RegistrationService,
    private databaseService: DatabaseService,
    private router: Router,
    private auth: AuthentificationService,
    private screenService: ScreenService
  ) {
    this.stringUtils = new StringUtils();
    this.message = "";
    this.messageColor = "";
  }

  columns = [
    {prop: "tournoi", name: "Tournoi", width: 280, cellClass: 'limegreen-text'},
    {prop: "joueur1", name: "Joueur 1", width: 170},
    {prop: "joueur2", name: "Joueur 2", width: 170},
    ]
  filterString: string;

  ngOnInit(): void {

    this.tournamentsMap = new Map<string, Tournament>();
    this.tournamentService.getTournaments()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
      this.tournaments = res.sort((a, b) => a.date.getTime() - b.date.getTime());

        this.dropdownOptions = this.tournaments.map(tournament => {
          return {
            label: this.getDateAndLocationString(tournament) + " " +
              this.getTournamentCount(tournament.id) + " " +
              this.getTournamentReserveCount(tournament.id),
            value: tournament.id
          };
        });

      console.log(this.tournaments)
      this.dataSource = new MatTableDataSource<Tournament>(this.tournaments);
      let allIds = this.tournaments.map(t => t.id);

      this.screenService.isMobileSubject.pipe(takeUntil(this.destroy$)).subscribe(isMobile => {
        this.registrationsService.getdata()
          .pipe(takeUntil(this.destroy$))
          .subscribe(res => {
            this.registrations = res
              .map(r => {
                let registration = RegistrationFactory.createFromDocChangeAction(r)
                return registration;
              }).filter(r => allIds.includes(r.tournamentId))
              .map(r => {
                let tournament = this.tournaments.find(t => t.id === r.tournamentId);
                return new Row(tournament! , r, isMobile);
              });
            if (this.isLoggedIn) {
              this.displayedRows = this.registrations.sort((a, b) => b.dateInscription.getTime() - a.dateInscription.getTime());
            } else {
              this.displayedRows = this.registrations.sort((a, b) => a.compareTo(b));
            }

            console.log(this.displayedRows)
          });
      });


    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getTournamentName(tournamentId: string): string {
    return StringUtils.getDateAndLocationString(
      this.tournaments.find((t) => t.id === tournamentId)
    );

  }

  onChange(event: Event) {
    console.log(event)
    let value = ((event as any).value as any).value;
    this.displayedRows = this.registrations.filter(r => r.idTournoi.includes(value));
  }

  goToEdit(id: string) {
    this.router.navigate(['/update-registration', {id: id}]);
  }

  delete(id: string) {
    this.registrationsService.deleteData(id).then(
      () => {
        this.registrations = this.registrations.filter(row => row.id !== id)
        this.snackbar.open("Inscription supprimée ! ", "OK")
      },
      () => {
        this.snackbar.open("Erreur lors de la suppression ! ", "Compris !")
      });
  }

  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn();
  }

  getRegistrationDateANdLocationString(row: any) {
    let tournament = this.tournaments.find(t => t.id === row.tournamentId);
    return StringUtils.getDateAndLocationString(tournament);
  }

  getTournamentCount(id: string | undefined) {
    let tournament = this.tournaments.find(t => t.id === id);
    return " (" + tournament?.registrationCount + " / " + tournament?.maxRegistrations + ")";
  }

  getTournamentReserveCount(id: string | undefined) {
    let tournament = this.tournaments.find(t => t.id === id);
    return " (Réserve: " + (tournament?.reserveRegistrationCount ?? 0) + " / " + (tournament?.maxReserveRegistrations ?? "?") + ")";
  }

  updateFilter() {

    this.displayedRows = this.registrations.filter(r => this.filterFunction(r));
  }

  filterFunction(r: Row) {
    return (r.tournoi.toLowerCase() + r.joueur1.toLowerCase() + r.joueur2.toLowerCase() + r.date.toString().toLowerCase()).includes(this.filterString.toLowerCase());
  }

  getMaxWidth() {
    console.log("Get max width")
    return this.isLoggedIn ? 'adminTable' : 'anonTable';
  }
}



interface RowInterface {
  tournoi: string;
  joueur1: string;
  joueur2: string;
  date: Date;
  idTournoi: string;
  isReserve?: boolean;

  compareTo(other: RowInterface): number;
}

class Row implements RowInterface {
  joueur1: string;
  joueur2: string;
  tournoi: string;
  id: string;
  idTournoi: string;
  isReserve?: boolean;
  date: Date;
  dateInscription: Date;

  constructor(
    tournament: Tournament,
    registration: Registration,
    isMobile: boolean
  ) {
    this.joueur1 = registration.player1;
    this.joueur2 = registration.player2;
    this.tournoi = StringUtils.getDateAndLocationString(tournament, isMobile);
    this.date = tournament.date;
    tournament.id ? (this.idTournoi = tournament.id) : (this.idTournoi = '');
    this.id = registration.id;
    this.dateInscription = registration.dateInscription || new Date();
    this.isReserve = registration.isReserve ?? false;
  }

  compareTo(other: RowInterface): number {
    let compare = this.date.getTime() - other.date.getTime();
    if (compare === 0) {
      compare = this.joueur1.localeCompare(other.joueur1);
      if (compare === 0) {
        compare = this.joueur2.localeCompare(other.joueur2);
      }
    }
    return compare;
  }
}


