import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      message: string,
      acceptButton: string,
      rejectButton: string,
      onAccept: () => void,
      onReject: () => void
    }
  ) {}

  onAccept(): void {
    this.data.onAccept?.();
    this.dialogRef.close();
  }

  onReject(): void {
    this.data.onReject?.();
    this.dialogRef.close();
  }
}
