import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { AddCampSequencyComponent } from '../add-camp-sequency/add-camp-sequency.component';
import { CampService } from '../../../services/db/camp.service';
import { ICampSequency } from '../../../models/camp';
import { AuthentificationService } from '../../../services/authentification.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeleteCampSequencies } from '../edit-camp-sequencies/delete-camp-sequencies.component';

@Component({
  selector: 'app-view-camp-sequencies',
  templateUrl: './view-camp-sequencies.component.html',
  styleUrls: ['./view-camp-sequencies.component.scss'],
  host: { class: 'base-host-center styled-background' },
})
export class ViewCampSequenciesComponent implements OnInit, OnDestroy {
  @Input() campId: string;
  destroy$ = new Subject<boolean>();

  faPlus = faPlus;
  faTrash = faTrash;
  sequencies: ICampSequency[] = [];
  isLoggedIn$: BehaviorSubject<boolean>;

  constructor(
    private matDIalog: MatDialog,
    private campService: CampService,
    private auth: AuthentificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.auth.isAuthenticated$;
    this.campService
      .getCampSequencies(this.campId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.sequencies = data;
        this.sequencies = this.sequencies.map((sequency: ICampSequency) => {
          sequency.fileDate = (sequency.fileDate as any)?.toDate();
          return sequency;
        }).sort((a, b) => a.fileDate.getTime() - b.fileDate.getTime());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addSequency() {
    this.matDIalog.open(AddCampSequencyComponent, {
      width: 'min-content',
      maxWidth: '100%',
      data: {
        campId: this.campId,
      },
    });
  }

  openDelete(id: string) {
    let seq = this.sequencies.find((sequency: any) => sequency.id === id);

    let dialog = this.dialog.open(DeleteCampSequencies, {
      data: { sequency: seq },
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.sequencies = this.sequencies.filter(
            (sequency: any) => sequency.id !== id
          );
        }
      });
  }
}
