import * as moment from "moment";
import {Tournament} from "../tournament";

export class StringUtils {
  static getDateAndLocationString(
    tournament?: Tournament,
    isMobile?: boolean
  ): string {
    if (tournament === undefined) return '';

    if(isMobile) {
      //date 1er janvier
      const date = moment(tournament.date).locale('fr');

      // Extract the day number and the day of the week
      const dayNumber = date.format('D'); // Day of the month
      const monthName = date.format('MMMM'); // Month name

      // @ts-ignore
      return  `${dayNumber} ${monthName}` + ' - ' +
        tournament?.lieu.split(' ')[1];
    } else {
      // @ts-ignore
      return (
        moment(tournament.date).locale('fr').format('LL') +
        ' - ' +
        tournament?.lieu
      );
    }

  }

  static getDateString(date: Date): string {
    // @ts-ignore
    return moment(date).locale('fr').format('LL');
  }

  static getDateStringLong(date: Date): string {
    return moment(date).locale('fr').format('LLL');
  }

  static getDateSmallFormat(date: Date): string {
    // @ts-ignore
    return moment(date).locale('fr').format('DD/MM/YYYY');
  }
}
