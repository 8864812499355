import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {InfosComponent} from './components/infos/infos.component';
import {DiaryComponent} from './components/diary/diary.component';
import {RegisterTournament} from './components/tournament/register-tournament/register-tournament.component';
import {Handicap22Component} from './components/handicap22/handicap22.component';
import {CoupeClpComponent} from './components/coupe-clp/coupe-clp.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {RegistrationFormComponent} from './components/registrations/registration-form/registration-form.component';
import {LoginFormComponent} from './components/forms/login-form/login-form.component';
import {FooterComponent} from './components/footer/footer.component';
import {NewTournamentComponent} from './components/tournament/new-tournament/new-tournament.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NewTournamentFormComponent} from './components/forms/new-tournament-form/new-tournament-form.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {getApp, initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {initializeFirestore, provideFirestore} from '@angular/fire/firestore';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFireModule, FIREBASE_OPTIONS} from '@angular/fire/compat';
import {TournamentListComponent} from './components/tournament/tournament-list/tournament-list.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {RegistrationListComponent} from './components/registrations/registration-list/registration-list.component';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {UploadHtmlComponent} from './components/result/upload-html/upload-html.component';
import {getStorage, provideStorage} from "@angular/fire/storage";
import {ResultListComponent} from './components/result/result-list/result-list.component';
import {MatListModule} from "@angular/material/list";
import {IgxIconModule, IgxLayoutModule, IgxListModule, IgxRippleModule} from "igniteui-angular";
import {ClPerronTeamsComponent} from './components/cl-perron/cl-perron-teams/cl-perron-teams.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditTournamentComponent } from './components/tournament/edit-tournament/edit-tournament.component';
import {CommonModule, DatePipe, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import { UpdateRegistrationComponent } from './components/registrations/update-registration/update-registration.component';
import { MondayChallengesComponent } from './components/general-rankings/monday-challenges/monday-challenges.component';
import { CLPerronRankingComponent } from './components/cl-perron/clperron-ranking/clperron-ranking.component';
import {HttpClientModule} from "@angular/common/http";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { MondayChampagneComponent } from './components/general-rankings/monday-champane/monday-champagne.component';
import { LinksComponent } from './components/liens/links.component';
import {MatTabsModule} from "@angular/material/tabs";
import { UploadFileFormComponent } from './components/result/upload-file-form/upload-file-form.component';
import { CommentaryListComponent } from './components/result/commentary-list/commentary-list.component';
import { ClPerronRulesComponent } from './components/cl-perron/cl-perron-rules/cl-perron-rules.component';
import { ClPerronCalendarComponent } from './components/cl-perron/calendar/cl-perron-calendar.component';
import { CompetitionClPerronTeamsComponent } from './components/competitions/competition-cl-perron-teams/competition-cl-perron-teams.component';
import { CompetitionCaptainsComponent } from './components/competitions/competition-captains/competition-captains.component';
import { MyCompetitionSystemComponent } from './components/competitions/my-competition-system/my-competition-system.component';
import {MatIconModule} from "@angular/material/icon";
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { NewNavBarComponent } from './components/new-nav-bar/new-nav-bar.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import { RTEComponent } from './components/rte/rte.component';
import {NgxEditorModule} from "ngx-editor";
import { IframeAUtoSizedComponent } from './components/iframe/iframe-auto-sized/iframe-auto-sized.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { HomeComponent } from './components/home/home.component';
import { CompetitionsComponent } from './components/competitions/competitions/competitions.component';
import { EditNewsComponent } from './components/infos/edit-news/edit-news.component';
import { ContactComponent } from './components/contact/contact.component';
import { NewMobileSidenavComponent } from './components/new-mobile-sidenav/new-mobile-sidenav.component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faS } from '@fortawesome/free-solid-svg-icons';
import { CreateTeamComponent } from './components/clp-manage-league/create-team/create-team.component';
import { UpdateTeamComponent } from './components/clp-manage-league/update-team/update-team.component';
import { ShowTeamsComponent } from './components/clp-manage-league/show-teams/show-teams.component';
import { DeleteTeamComponent } from './components/clp-manage-league/delete-team/delete-team.component';
import { CreateTeamResultComponent } from './components/teamResult/create-team-result/create-team-result.component';
import { UpdateTeamResultComponent } from './components/teamResult/update-team-result/update-team-result.component';
import { DeleteTeamResultComponent } from './components/teamResult/delete-team-result/delete-team-result.component';
import { ViewTeamResultsComponent } from './components/teamResult/view-team-results/view-team-results.component';
import { ClpInfosComponent } from './components/cl-perron/clp-infos/clp-infos.component';
import {MatDialogModule} from "@angular/material/dialog";
import { AjouterSequenceComponent } from './components/teamResult/ajouter-sequence/ajouter-sequence.component';
import { SupprimerSequenceComponent } from './components/teamResult/upprimer-sequence/supprimer-sequence.component';
import { ViewSequenciesComponent } from './components/teamResult/view-sequencies/view-sequencies.component';
import { CampForm } from './components/camps/create-camp/camp-form.component';
import { CampNavListComponent } from './components/camps/camp-nav-list/camp-nav-list.component';
import { ViewCampComponent } from './components/camps/view-camp/view-camp.component';
import { AuthorizeCampComponent } from './components/camps/authorize-camp/authorize-camp.component';
import { ViewCampPlayersComponent } from './components/camps/player/view-camp-players/view-camp-players.component';
import { CampPlayerFormComponent } from './components/camps/player/camp-player-form/camp-player-form.component';
import { DeleteCampPlayerComponent } from './components/camps/player/delete-camp-player/delete-camp-player.component';
import { ViewCampPicturesComponent } from './components/camps/pictures/view-camp-pictures/view-camp-pictures.component';
import { AddPictureComponent } from './components/camps/pictures/add-picture/add-picture.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewCampResultComponent } from './components/camps/results/view-camp-result/view-camp-result.component';
import { CampResultFormComponent } from './components/camps/results/camp-result-form/camp-result-form.component';
import { ViewCampSequenciesComponent } from './components/camps/view-camp-sequencies/view-camp-sequencies.component';
import { AddCampSequencyComponent } from './components/camps/add-camp-sequency/add-camp-sequency.component';
import { UpdateCampResultsComponent } from './components/camps/results/update-camp-results/update-camp-results.component';
import {AngularFirePerformanceModule} from "@angular/fire/compat/performance";
import { DeleteCampResultComponent } from './components/camps/results/delete-camp-result/delete-camp-result.component';
import { CampProgramComponent } from './components/camps/camp-program/camp-program.component';
import { CampBidComponent } from './components/camps/camp-bid/camp-bid.component';
import { CampPlanComponent } from './components/camps/camp-plan/camp-plan.component';
import { EditCampComponent } from './components/camps/edit-camp/edit-camp.component';
import { DeleteCampComponent } from './components/camps/delete-camp/delete-camp.component';
import { DeleteCampSequencies } from './components/camps/edit-camp-sequencies/delete-camp-sequencies.component';
import { CampInformationsComponent } from './components/camps/camp-informations/camp-informations.component';
import { CampBidFOrmComponent } from './components/camps/camp-bid/camp-bid-form/camp-bid-form.component';
import { CampHtmlResultsComponent } from './components/camps/add-camp-sequency/camp-html-results/camp-html-results.component';
import { DynamicTitleComponent } from './components/titles/dynamic-title/dynamic-title.component';
import {GalleriaModule} from "primeng/galleria";
import {ButtonModule} from "primeng/button";
import {CardModule} from "primeng/card";
import {OrderListModule} from "primeng/orderlist";
import { ViewPlayersComponent } from './components/competition/folder/view-players/view-players.component';
import {DataViewModule} from "primeng/dataview";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {AnimateModule} from "primeng/animate";
import {ConfirmationService} from "primeng/api";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {TooltipModule} from "primeng/tooltip";
import { ResultArrayComponent } from './components/competition/folder/view-results/result-array.component';
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import { StateToggleButtonComponent } from './components/competition/buttons/state-toggle-button/state-toggle-button.component';
import { MondayResultsComponent } from './components/competition/folder/monday-results/monday-results.component';
import { CustomDatePipe } from './models/competition/pipes/custom-date.pipe';
import { CustomFullDatePipe } from './models/competition/pipes/custom-full-date.pipe';
import { ClpResultsComponent } from './components/competition/folder/clp-results/clp-results.component';
import { CampResultComponent } from './components/competition/folder/camp-result/camp-result.component';
import { SetCoupeTitleComponent } from './components/titles/set-coupe-title/set-coupe-title.component';
import {DropdownModule} from "primeng/dropdown";
import {CheckboxModule} from "primeng/checkbox";
import { ConfirmationDialogComponent } from './components/utils/confirmation-dialog/confirmation-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { CampResultHtmlComponent } from './components/camps/camp-result-html/camp-result-html.component';
import {ConfirmDialogModule} from "primeng/confirmdialog";


library.add(faS);

@NgModule({
  declarations: [
    AppComponent,
    InfosComponent,
    DiaryComponent,
    RegisterTournament,
    Handicap22Component,
    CoupeClpComponent,
    NavBarComponent,
    RegistrationFormComponent,
    RegistrationFormComponent,
    LoginFormComponent,
    FooterComponent,
    NewTournamentComponent,
    NewTournamentFormComponent,
    TournamentListComponent,
    RegistrationListComponent,
    UploadHtmlComponent,
    ResultListComponent,
    ClPerronTeamsComponent,
    EditTournamentComponent,
    UpdateRegistrationComponent,
    MondayChallengesComponent,
    CLPerronRankingComponent,
    MondayChampagneComponent,
    LinksComponent,
    UploadFileFormComponent,
    CommentaryListComponent,
    ClPerronRulesComponent,
    ClPerronCalendarComponent,
    CompetitionClPerronTeamsComponent,
    CompetitionCaptainsComponent,
    MyCompetitionSystemComponent,
    NewNavBarComponent,
    RTEComponent,
    IframeAUtoSizedComponent,
    HomeComponent,
    CompetitionsComponent,
    EditNewsComponent,
    ContactComponent,
    NewMobileSidenavComponent,
    CreateTeamComponent,
    UpdateTeamComponent,
    ShowTeamsComponent,
    DeleteTeamComponent,
    CreateTeamResultComponent,
    UpdateTeamResultComponent,
    DeleteTeamResultComponent,
    ViewTeamResultsComponent,
    ClpInfosComponent,
    AjouterSequenceComponent,
    SupprimerSequenceComponent,
    ViewSequenciesComponent,
    CampForm,
    CampNavListComponent,
    ViewCampComponent,
    AuthorizeCampComponent,
    ViewCampPlayersComponent,
    CampPlayerFormComponent,
    DeleteCampPlayerComponent,
    ViewCampPicturesComponent,
    AddPictureComponent,
    ViewCampResultComponent,
    CampResultFormComponent,
    ViewCampSequenciesComponent,
    AddCampSequencyComponent,
    UpdateCampResultsComponent,
    DeleteCampResultComponent,
    CampProgramComponent,
    CampBidComponent,
    CampPlanComponent,
    EditCampComponent,
    DeleteCampComponent,
    DeleteCampSequencies,
    CampInformationsComponent,
    CampBidFOrmComponent,
    CampHtmlResultsComponent,
    DynamicTitleComponent,
    ViewPlayersComponent,
    ResultArrayComponent,
    StateToggleButtonComponent,
    MondayResultsComponent,
    CustomDatePipe,
    CustomFullDatePipe,
    ClpResultsComponent,
    CampResultComponent,
    SetCoupeTitleComponent,
    ConfirmationDialogComponent,
    CampResultHtmlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() =>
      initializeFirestore(getApp(), { experimentalAutoDetectLongPolling: true })
    ),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirePerformanceModule,
    FormsModule,
    MatSortModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatListModule,
    IgxListModule,
    IgxIconModule,
    IgxRippleModule,
    FontAwesomeModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    IgxLayoutModule,
    MatTooltipModule,
    MatDialogModule,
    CommonModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
    NgbModule,
    GalleriaModule,
    ButtonModule,
    CardModule,
    OrderListModule,
    DataViewModule,
    InputTextModule,
    RippleModule,
    AnimateModule,
    ConfirmPopupModule,
    TooltipModule,
    TableModule,
    ToolbarModule,
    NgOptimizedImage,
    DropdownModule,
    CheckboxModule,
    MatCheckboxModule,
    ConfirmDialogModule,
  ],
  providers: [
    AngularFireAuthModule,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
